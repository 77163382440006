<template>
  <t-page>
    <template #header>
      <h3 slot="header">
        {{ $t('fields.employees') }}
      </h3>
      <nuxt-link
        :to="{ name: $RouteEnum.ORGANIZATION.INVITATIONS, query: { 'show-create-modal': true } }"
      >
        <t-button type="link">
          {{ $t('pages.organization.actions.invite_colleagues') }}
        </t-button>
      </nuxt-link>
    </template>
    <t-grid>
      <t-grid-cell>
        <employees-index-table :query="query" :filters="filters" />
      </t-grid-cell>
    </t-grid>
  </t-page>
</template>

<script>
import Vue from 'vue';
import Employee from '~/models/Employee';
import EmployeesIndexTable from '~/pages/employee/organization/partials/EmployeesIndexTable';

export default Vue.extend({
  components: { EmployeesIndexTable },

  middleware: 'employee',

  data: () => ({
    filters: [],
  }),

  computed: {
    query() {
      return () =>
        new Employee().include('user').where('organization_id', this.$auth.me.organization_id);
    },
  },
});
</script>
